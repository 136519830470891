<template>
	<Layout>
		<template v-slot:other>
			<DialogLoginLine/>
			<AdvertisingImages device="mobile"/>
		</template>
	</Layout>
</template>
<script>
	const Layout = require(`~/components/mobile/layouts/${process.env.M_DEF_webFrame}.vue`).default;
	import { mapState, mapMutations, mapGetters } from "vuex";
	import DialogLoginLine from "~/components/common/dialogLoginLine.vue";
	import AdvertisingImages from "~/components/mobile/advertisingImages.vue";
	export default {
		middleware: [
			"slideshow",
			"seo"
		],
		components:{
			Layout,
			DialogLoginLine,
			AdvertisingImages
		},
		mounted() {
			if (this.FirstAnnouncenent && this.ShowAnnouncenentPopup) {
				this._setFirstAnnouncenent(false);
				this.$root.$emit("bv::toggle::modal", "modalInfo", "#btnToggle");
			}
		},
		methods: {
			...mapMutations(['_setFirstAnnouncenent'])
		},
		computed: {
			...mapState(['FirstAnnouncenent']),
			...mapGetters('webSetting', ['ShowAnnouncenentPopup'])
		}
	};
</script>